import React, { useRef, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./style.css";
import spotlightWelcomeBanner from "../../assets/image/welcome_banner_spotlight.png";
import storybookWelcomeBanner from "../../assets/image/storybookBanner.jpg";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { appMapping } from "../../constants";
import Loader from "../loader";

export const WelcomeScreen = () => {
	const { eventVerfiyData, loading, secondLoading } = useSelector(
		(store: any) => store?.event
	);
	const welcomeScreenFooterRef = useRef(null);
	const [imgMaxHeight, setImgMaxHeight] = useState(0);
	const navigate = useNavigate();
	const { id } = useParams();
	//@ts-ignore
	const currentProduct = appMapping[eventVerfiyData?.distinctName];

	useEffect(() => {
		if (welcomeScreenFooterRef?.current) {
			//@ts-ignore
			setImgMaxHeight(welcomeScreenFooterRef?.current?.offsetHeight); // to fit the contents and the image to the screen without scroll
		}
	}, [eventVerfiyData?.[currentProduct?.keyName]?.showName]);

	return (
		<Grid
			container
			spacing={0}
			justifyContent='center'
			sx={{ height: "100svh", overflow: "hidden" }}
		>
			{(loading || secondLoading) && <Loader />}
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				sx={{ height: "100svh", overflow: "hidden" }}
			>
				<div className='welcome-screen-container text-center'>
					{!!currentProduct?.productBanner && (
						<img
							src={currentProduct?.productBanner}
							alt='welcome banner'
							style={{ maxHeight: `calc(100% - (${imgMaxHeight + 32}px))` }}
						/>
					)}

					<div ref={welcomeScreenFooterRef} className='welcome-screen__footer'>
						<h1>{eventVerfiyData?.[currentProduct?.keyName]?.showName}</h1>
						<button
							className='pri-btn'
							style={{
								backgroundColor: currentProduct?.themeColor,
							}}
							onClick={() => navigate(`/signup/${id}`)}
						>
							enter event
						</button>
						<p className='privacy_policy'>
							By clicking enter event button, you agree to our{" "}
							<a
								className='anchor'
								href='https://www.esstart.com/tos'
								target='_blank'
								rel='noreferrer'
							>
								Terms of use
							</a>{" "}
							and{" "}
							<a
								href='https://www.esstart.com/p-policy'
								className='anchor'
								target='_blank'
								rel='noreferrer'
							>
								Privacy Policies
							</a>
						</p>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};
