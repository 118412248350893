import React from "react";
import { Grid, Link, Typography, Button, Divider, Box } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EventValidate } from "../validation";
import { useSelector, useDispatch } from "react-redux";
import { Container, styled } from "@mui/system";
import { Footer } from "../footer";
import { Header } from "../header";
import HeroBanner from "../../assets/image/landing_banner.png";
import Oopsimage from "../../assets/image/Oops_img_2x.jpg";
import { useParams } from "react-router-dom";
import { eventVerify } from "../../redux/event";
// import Loader from "../../assets/image/Esstart_spinner.gif";
import { getCookie, setCookie } from "../../utils/Cookies";
import Loader from "../loader";

const FormBox = styled(Box)`
.form_field {
  border-radius: 4px;
  position: relative;
  background-color: theme.palette.mode=== 'light' ? '#fcfcfb' :'#2b2b2b';
  border: 1px solid #B5B5B5;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 0 12px;
  height: 48px;
  margin-top: 5px;
  outline: none;
},
`;

const StyleContainer = styled(Container)`
	padding: 0px;

	.Oops_img {
		display: flex;
		margin: 10px auto 0;
	}
	.errorMsg {
		color: red;
	}
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: #00000050;
		z-index: 1111;
		align-items: center;
		justify-content: center;
	}
	.loader img {
		width: 100px;
	}
	label {
		font: normal normal normal 14px/18px Montserrat;
	}
`;

export const Home = () => {
	document.title = "Esstart.com > Guest Landing";
	sessionStorage.setItem("gallery", "no");
	const event: any = useSelector((state: any) => state.event);
	const [searchParams, setSearchParams] = useSearchParams();
	const eventCode = getCookie("eventCode");
	const navigate = useNavigate();

	const [isPageShow, setIsPageShow] = React.useState<Boolean>(false);
	const [addEventClicked, setAddEventClicked] = React.useState(false);
	const dispatch = useDispatch<any>();

	const { id } = useParams();
	const lookup = searchParams.get("lookup");

	React.useEffect(() => {
		(async () => {
			if (id) {
				await dispatch(eventVerify({ eventCode: id }));
				setAddEventClicked(true);
			}
			if (lookup) {
				const res = await dispatch(eventVerify({ lookup: lookup }));
				const commonQrUrl = res?.payload?.data?.commonQrUrl;
				if (!!commonQrUrl) {
					window.location.href = commonQrUrl;
				}
				setFieldValue("eventCode", res?.payload?.data?.activityCode);
				setAddEventClicked(true);
			}
		})();
	}, [id, dispatch, lookup]);

	React.useEffect(() => {
		if (addEventClicked) {
			if (event.responseStatus === "success") {
				const activityCode = id ? id : values.eventCode;
				const eventCodeExpiry = new Date(
					new Date().getTime() + 60 * 60 * 1000 * 3
				);
				setCookie("eventCode", activityCode || "", {
					expires: eventCodeExpiry,
					// domain: getDomain()
				});
				// navigate(`/signup/${activityCode}`);
				navigate(`/welcome/${activityCode}`);
			} else if (event.responseStatus === "error") {
				setIsPageShow(true);
			}
		}
	}, [addEventClicked, event.responseStatus, id]);

	const handleTryAgain = () => {
		setAddEventClicked(false);
		setIsPageShow(false);
	};

	const {
		handleChange,
		handleBlur,
		handleSubmit,
		values,
		errors,
		touched,
		setFieldValue,
	} = useFormik({
		initialValues: {
			eventCode: id || eventCode || "",
		},
		validate: EventValidate,
		onSubmit: async (values: any) => {
			const { eventCode } = values;
			const res = await dispatch(eventVerify({ eventCode }));
			setAddEventClicked(true);
		},
	});

	return (
		<StyleContainer>
			{(event.loading || event.secondLoading) && <Loader />}
			{process.env.REACT_APP_CUSTOM_NODE_ENV !== "production" && (
				<button
					onClick={() => {
						alert(
							`${getCookie("cookie1")}---------------${localStorage.getItem(
								"local1"
							)}---------------${sessionStorage.getItem(
								"session1"
							)}-----------------${getCookie(
								"refreshToken"
							)}-----------------${getCookie("accessToken")}`
						);
					}}
				>
					Alert btn
				</button>
			)}

			<Grid container spacing={0} justifyContent='center'>
				<Grid item xs={12} sm={8} md={5}>
					<Header />
					<Box position='relative' style={{ minHeight: "calc(100vh - 75px)" }}>
						<Box sx={{ paddingBottom: "130px" }} paddingX={2}>
							{isPageShow ? (
								<>
									<img src={HeroBanner} alt='Hero Banner' width='100%' />

									<img
										src={Oopsimage}
										alt='Oops'
										className='Oops_img'
										width='140'
									/>

									<Divider style={{ margin: "15px auto", width: "80%" }} />

									<Typography
										variant='h4'
										textAlign='center'
										color='#202020'
										component='h2'
									>
										Oops!
									</Typography>

									<Typography
										margin='10px 0 0'
										variant='body1'
										textAlign='center'
										component='p'
									>
										Not a Valid activity code or
									</Typography>
									<Typography
										margin='0 0 10px'
										variant='body1'
										textAlign='center'
										component='p'
									>
										Event does not accept guests at this time
									</Typography>

									<Button
										variant='contained'
										style={{
											margin: "0 auto",
											display: "flex",
											background: "#E40000",
											borderRadius: "20px",
											fontSize: 14,
											lineHeight: "18px",
											height: "40px",
											width: "198px",
											boxShadow: "none",
										}}
										onClick={() => handleTryAgain()}
									>
										TRY AGAIN
									</Button>
								</>
							) : (
								<>
									<img src={HeroBanner} alt='Hero Banner' width='100%' />
									<Typography paddingY={2} variant='body1' component='p'>
										Please enter event activity code you want to join.
									</Typography>
									<FormBox>
										<form onSubmit={handleSubmit}>
											<label>Activity Code</label>
											<input
												className='form_field'
												onBlur={handleBlur}
												onChange={handleChange}
												type='text'
												placeholder='Activity Code'
												id='eventCode'
												maxLength={7}
												value={values.eventCode}
											/>
											{errors.eventCode && touched.eventCode ? (
												<div className='errorMsg'>
													{errors.eventCode as string}
												</div>
											) : null}

											<Button
												type='submit'
												variant='contained'
												style={{
													margin: "30px auto 0",
													display: "flex",
													background: "#E40000",
													borderRadius: "20px",
													fontSize: 14,
													lineHeight: "18px",
													height: "40px",
													width: "198px",
													boxShadow: "none",
												}}
												disabled={event.loading}
											>
												ENTER EVENT
											</Button>
										</form>
									</FormBox>
								</>
							)}
							<Box
								position={"absolute"}
								bottom={75}
								alignItems={"center"}
								width={"calc(100% - 32px)"}
							>
								<Typography
									textAlign='center'
									margin='0 auto'
									maxWidth='350px'
									fontSize='14px'
									lineHeight='18px'
									variant='body2'
									component='p'
								>
									By submitting this form, you are agreeing to our{" "}
									<Link
										href='https://www.esstart.com/tos'
										target='_blank'
										color='#009FB7'
										style={{ textDecoration: "none" }}
									>
										Terms and Conditions
									</Link>
								</Typography>
							</Box>
						</Box>
						<Footer />
					</Box>
				</Grid>
			</Grid>
		</StyleContainer>
	);
};
